<template>
  <div>
    <Home v-if="!isMobile()"></Home>
  </div>
</template>

<script>
import Home from '@/components/Home.vue';
import Gift from '@/components/gift.vue';
import { isMobileF } from '@/utils/utils'
export default {
  components: {
    Home,
    Gift,
  },
  data() {
    return {};
  },
  name: 'Index',
  mounted() {},
  methods: {
    close() {
      this.isShow = false;
    },
    isMobile() {
      let ua = navigator.userAgent;
      let ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid;
      return isMobile;
    },
    isWeixin() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf('micromessenger') !== -1;
    },
  },
  beforeCreate() {
    this.$router.afterEach((to, from) => {
      if (this.isWeixin() || isMobileF()) {
        location.href = '/mobile';
        return;
      }
      this.delay = to.meta.delay > 0;
      setTimeout(() => {
        this.fs = true;
      }, to.meta.delay || 0);
    });
  },
};
</script>
