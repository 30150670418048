import Vue from 'vue'
import App from './Index.vue'
import Hrouter from './router'
import CSrouter from './csRouter'
import store from '@/common/store'
import Axios from '@/common/axios'
import Utils from '@/common/utils'
import Setting from '@/config/setting'
import isLogin from '@/common/login'
import VueGlobalConfig from '@/common/vueGlobalConfig'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.scss';
import '@/assets/css/view.scss';
import '@/assets/fonts/font-awesome/css/font-awesome.min.css'
import "@/assets/fonts/iconfont/iconfont.css"
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// elementUI 引入
import ElementUI from 'element-ui';
import { dealLocationSearch } from '@/mobile/utils/utils';
import { isSupportWebp } from '@/utils/utils';

Vue.use(ElementUI);

Vue.config.productionTip = false
var router = process.env.VUE_APP_CSZYHOST === location.host ? CSrouter : Hrouter
Vue.use(Axios, {  router });
Vue.use(Utils);
Vue.use(Setting);
Vue.use(VueGlobalConfig, {
  mixin: {
    getter: true,
    mutations: true,
    actions: true
  }
});
const supportWebp = isSupportWebp()
Vue.prototype.isSupportWebp = supportWebp

// 页面权限控制
// router.beforeEach((to, from, next) => {
//   let token = store.getters.getAccessToken
//   let refreshToken = store.getters.getRefreshToken
//   if (!token && !refreshToken && to.meta.needLogin === true) {
//     next({
//       path: '/login'
//     })
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  const query = dealLocationSearch();
  if (query.redirect) {
    localStorage.setItem('redirect', 'false')
  }
  if (to.meta.needLogin === true) { // 需要登录
    // 判断是否登录
    isLogin().then(() => {
      next()
    }).catch(() => {
      next({
        path: '/login'
      })
    })
  } else {
    store.commit('UPDATE_LOGIN_STATUS', to.path != "/login")
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app') 