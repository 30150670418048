import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "*",
      name: "index",
      meta: {
        divHelper: "g-doc",
        title: ""
      },
      component: () => import("./cszy.vue")
    }
    // {
    //   path: "/login",
    //   name: "login",
    //   meta: {
    //     divHelper: "g-doc",
    //     // needLogin: true,
    //     title: "登录"
    //   },
    //   component: () => import("./Login.vue")
    // }
  ]
});
